var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"image-text-horizontal-grid flex flex-col gap-4 container mx-auto desktop:flex-row desktop:gap-8",class:{
      'tablet:items-center' : _vm.verticalAlignCenter,
      'image-text-horizontal-grid image-50 justify-between gap-4 tablet:gap-[120px]': _vm.imageSize === '50'
    }},[_c('div',{staticClass:"img-wrapper w-full max-w-[360px]",class:{
        'desktop:order-last': _vm.mirror,
        'desktop:order-none': !_vm.mirror,
        'w-full desktop:w-[50%] max-w-none': _vm.imageSize === '50'
      }},[_vm._t("image")],2),_vm._v(" "),_c('div',{class:{
        'grow tablet:w-3/4': _vm.imageSize === 'auto',
        'w-full max-w-[600px]': _vm.imageSize === '50',
      }},[_c('div',{staticClass:"mb-m"},[_vm._t("headline")],2),_vm._v(" "),_c('p',{staticClass:"mb-s mr-subline-lrg-bold"},[_vm._v("\n        "+_vm._s(_vm.preline)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-s mr-h3-med text-mr-primary"},[_vm._v("\n        "+_vm._s(_vm.introText)+"\n      ")]),_vm._v(" "),_c('div',{class:'image-text-horizontal-grid-columns w-full mb-l tablet:mb-s flex flex-col gap-6 desktop:flex-row'},[_vm._t("columns")],2),_vm._v(" "),(_vm.buttons.length > 0)?_c('div',{staticClass:"flex flex-col desktop:flex-row gap-4"},[_vm._t("buttons")],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }