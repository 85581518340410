

export default {
  props: {
    headline: {
      type: Array,
    },
    preline: {
      type: String,
    },
    introText: {
      type: String,
    },
    image: {
      type: Array,
    },
    columns: {
      type: Array,
    },
    buttons: {
      type: Array,
    },
    mirror: {
      type: Boolean,
      default: false,
    },
    verticalAlignCenter: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: String,
      default: 'auto',
      validator: (value) => ['', 'auto', '50'].includes(value),
    },
  },
};

